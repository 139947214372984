import React from 'react';


import './App.css';
import './components/WeatherScreen/searchCity.css';
import WeatherScreen from './components/WeatherScreen/WeatherScreen'



const App = () => (
    <div>
     <WeatherScreen />
    </div>
);

export default App;


